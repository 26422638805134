//import { Link } from "@StarberryUtils";
import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import { Button, Navbar, NavDropdown, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
// import SearchBlock from "../SearchBlock/SearchBlock";
import whatsapp from "../../images/footer/whatsapp.png";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"
import { GetURL } from "../common/site/functions";

// Images
import BrandLogo from "../../images/logo.svg"
import tabBrandLogo from "../../images/header/tab-logo.svg"
import './Header.scss';
import Loadable from "@loadable/component"
const GoogleTranslate = Loadable(() => import("../GoogleTranslateWidget/GoogleTranslateWidget"))
const SearchBlock = Loadable(() => import("../SearchBlock/SearchBlock"))

const Header = (props) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menuHeader {
        Main_menu {
              Label
              Link {
                URL
                id
              }
              Sub_Menus {
                Label
                Link {
                  URL
                  id
                }
              }
            }
          }
        siteConfig {
            Facebook_Link
            Instagram_Link
            Linkedin_Link
            Youtube_Link
            Phone
            Available_Timings
        }
    }
  }
`);
  const menus = data.glstrapi.menuHeader;
  const [scroll, setScroll] = useState(false);
  const [propertySearch, setPropertySearch] = useState({ buy: false, rent: false })
  const [renderComponent, setRenderComponent] = useState(false);
  const location = useLocation();
  const logoWhite = `${process.env.GATSBY_SITE_URL}/images/logo.svg`;
  const [show, setShow] = useState({});
  const showDropdown = (name) => {
    setShow({
      [name] : !show[name]
    });
  }
  const hideDropdown = name => { 
    setShow({
      [name] : false
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60)
    })
    window.addEventListener("mousemove", () => {

      if (renderComponent === false) {
        scriptInsert()
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        scriptInsert()
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        scriptInsert()
        setRenderComponent(true)
      }
    })
  }, [])

  const scriptInsert = () => {
    if (document.getElementById("chat") === null) {
      var script = document.createElement('script');
      script.id = 'chat';
      script.src = '//code.jivosite.com/widget/fOl4Wvtle2';
      document.getElementsByTagName('head')[0].appendChild(script);
      //Microsoft clarity event listener js
      (function (c, l, a, r, i, t, y) {

        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };

        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i + "?ref=bwt";

        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);

      })(window, document, "clarity", "script", "g80an0zegk");


      // home article id
      if (typeof window !== "undefined" && props.homeArticleId?.id == "609a85c0e649554c60d8d13c") {
        var meta = document.createElement('meta');
        meta.name = "yandex-verification";
        meta.content = "9779a02309d58b3d";
        document.getElementsByTagName('head')[0].appendChild(meta);

        var googleVerification = document.createElement('meta');
        googleVerification.name = "google-site-verification";
        googleVerification.content = "la8qX1x4Od4EgmC3R4SU_DttOTBStjqCflJh9jIhCF4";
        document.getElementsByTagName('head')[0].appendChild(googleVerification);
      }
      if (typeof window !== "undefined") {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = '(function(m,e,t,r,i,k,a){m[i]=m[i]|| function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0 ; j < document.scripts.length; j++){if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(91132752, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true });';
        document.getElementsByTagName('head')[0].appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = '<div><img src="https://mc.yandex.ru/watch/91132752" style="position:absolute; left:-9999px;" alt="" /></div>';
        document.getElementsByTagName('head')[0].appendChild(noscript);
      }
    }
  }


  if (props.fixed && scroll) {
    if (typeof document !== 'undefined') {
      document.body.classList.add('select-fixed')
      document.body.classList.add('default-class')
    }
  } else {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('select-fixed')
      document.body.classList.add('default-class')
    }
  }

  var location_link = process.env.GATSBY_SITE_URL + location.pathname;

  var whatsapp_link = `https://wa.me/97144232006?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
  if (location.search.indexOf("utm_source") > 0) {
    whatsapp_link = `https://wa.me/97144232006?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
  }
 
  return (
    <>

      <header className={props.fixed && scroll ? "header--fixed header" : "header"} onMouseLeave={() => setPropertySearch({ buy: false, rent: false })}>
        <Container fluid>
          <Row>
            <Col>
              <span className="mobile-menu d-md-none" onClick={props.handlerClick}></span>
              <Nav>
                <Navbar expand="xl">
                  <Navbar.Brand className="navbar-whatsapp" to="#home">
                    <Link to="/">
                      <picture>
                        <source media="(min-width:992px)" srcset={logoWhite} />
                        <img loading="lazy" className="logo" src={logoWhite} alt="Dacha logo" />
                      </picture>
                    </Link>
                  </Navbar.Brand>
                  {/* <Navbar.Collapse className="d-none navigation-wrap"  >
                    <Nav>
                      <div className="navigation dacha-topnav" >
                        <ul>
                          <li>
                            <Link to={"/buy/"} activeClassName="active">Buy</Link>
                          </li>
                          <li>
                            <Link to={"/rent/"} activeClassName="active">Rent</Link>
                          </li>
                          <li>
                            <Link to={"/sell-your-property/"} activeClassName="active">Sell</Link>
                          </li>
                          <li>
                            <Link to="/off-plan-properties/" activeClassName="active">Off Plan</Link>
                          </li>
                          <li>
                            <Link to="/holiday-homes/" activeClassName="active"> Holiday Homes</Link>
                          </li>
                        </ul>
                        
                      </div>
                    </Nav>
                  </Navbar.Collapse> */}
                  <Navbar.Collapse className="d-none navigation-wrap" id="basic-navbar-nav">
                    <div className="navbar-nav">
                      <div className="navigation dacha-topnav">
                        <ul>
                          {menus && menus.Main_menu.map((parentMenu, index, active) => {
                            return (
                              <li key={index}>
                                {parentMenu.Sub_Menus.length > 0 ?
                                  <NavDropdown title={parentMenu.Label}
                                    id={`collasible-nav-dropdown ${index}`}
                                    show={show[index]}
                                    onMouseEnter={()=>{showDropdown(index)}}
                                    onMouseLeave={()=>{hideDropdown(index)}}
                                    key={index}
                             
                                  >
                                  
                                    {parentMenu.Sub_Menus.map((subMenu, index, active) => {
                                      var url = "#"
                                      if (subMenu.Link) {
                                        url = `/${GetURL(subMenu.Link.id)}`
                                      }
                                      return (
                                        <> 
                                          <Link to={url} className="dropdown-item" activeClassName="active"> {subMenu.Label}</Link>
                                        
                                        </>
                                      )
                                    })}
                                  </NavDropdown>
                                  : parentMenu.Link ?
                                    <Link to={`/${GetURL(parentMenu.Link?.id)}`} activeClassName="active"> {parentMenu.Label}</Link>
                                    :
                                    <a href="javascript.void(0)" activeClassName="active">{parentMenu.Label}</a>
                                }

                              </li>
                            )
                          })}


                        </ul>
                      </div>

                    </div>

                  </Navbar.Collapse>

                  {/* Google Translate Widget Start */}
                  <div className="ml-auto d-md-flex lang-btn google-translate-widget d-block d-md-block d-lg-block">
                    {renderComponent &&
                      <GoogleTranslate />
                    }
                  </div>
                  {/* Google Translate Widget End */}

                  <div className="d-block d-lg-none whatsapp-tablet">
                    {/*<Link to={"/ru/"} className="btn btn-primary ru-btn-mobile">RU</Link>*/}
                    <a href={whatsapp_link} target="_blank" className="whatsapp"><img loading="lazy" src={whatsapp} alt="whatsapp" /></a>
                  </div>

                  <div className="nav-right ml-auto d-none d-md-flex widget-google-translate">
                    {/* <Link to={"/ru/"} className="btn btn-primary ru-btn-desk">RU</Link> */}
                    <Link to="/contact" className="btn btn-primary cont-btn"><i className="icon-mobile"></i> Contact Us</Link>
                    <Button className="btn btn-outline" onClick={props.handlerClick} variant="link">
                      <i className="menu-icon"></i>  Menu
                    </Button>
                  </div>
                </Navbar>
              </Nav>
            </Col>
          </Row>
        </Container>
      </header>
      {renderComponent &&
        <div className="search-section">
          <SearchBlock showMenu={props.showMenu} toggleSearch={props.handlerClick} />
        </div>
      }
    </>
  )
}



export default Header