import React, { useState,useEffect } from "react"
import { Button, Container, Row, Col } from "react-bootstrap"
import logo from "../../images/footer/footer-logo.svg"
import whatsapp from "../../images/footer/whatsapp.png"
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils";
import loadable from "@loadable/component";
import { GetURL } from "../common/site/functions";
import HTMLReactParser from 'html-react-parser';
import { Helmet } from "react-helmet";
// import PopularSearch from "./PopularSearch/PopularSearch";
import { useLocation } from "@reach/router"
import { StarBerry } from "./icon";
import './Footer.scss';
import axios from "axios";

const PopularSearch = loadable(() => import("./PopularSearch/PopularSearch"));

// markup
const Footer = (props) => {
    const data = useStaticQuery(graphql`
  query {
    glstrapi {
        menuFooter {
            Add_Menu {
              Label
              Link {
                URL
              }
              Sub_Menus {
                Label
                Link {
                  URL
                  id
                }
              }
            }
          }
        siteConfig {
            Facebook_Link
            Instagram_Link
            Linkedin_Link
            Youtube_Link
            Phone
            Available_Timings
        }
    }
  }
`);


    const menus = data.glstrapi.menuFooter;
    const site = data.glstrapi.siteConfig;
    const [showSearch, setShowSearch] = useState(true);
    const logoBlue = `${process.env.GATSBY_SITE_URL}/images/logo-blue.svg`;

    {/* Change Contact Number Only For /holiday-homes/ Page => https://starberry.monday.com/boards/436093956/pulses/2525410473 */ }
    const currentpage = typeof window !== 'undefined' ? window.location.pathname : ''
    if (currentpage == '/holiday-homes/') {
        var contactnumber = "+971 52 850 0156";
    } else {
        var contactnumber = "" + site.Phone;
    }
    const number = contactnumber;
    let [reviewCount, setReviewCount] = React.useState(0);
    let [averageRating, setAverageRating] = React.useState(0);
    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }
     const urlReviws = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`;
      const getitems = async url => {
        try {
          const { data } = await axios.get(url,strapiconfig)// could be from env files 
          setReviewCount(data.length);
          let sumOfScores = 0;
          data.map((review) => {
            const reviewStringValue = review.starRating;
            sumOfScores += wordToNumber[reviewStringValue];
          })
          setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
        } catch (error) {
          // cache it if fail/error;
    
        }
      }
    useEffect(() => {
        getitems(urlReviws);
      }, []);
    const displaySearch = () => {
        setShowSearch(!showSearch)
    }
    const year = new Date().getFullYear();

    const location = useLocation();

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("touchmove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
  
    }, [])

    var location_link = process.env.GATSBY_SITE_URL+location.pathname;

    var whatsapp_link = `https://wa.me/97144232006?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
    if(location.search.indexOf("utm_source") > 0){
        whatsapp_link = `https://wa.me/97144232006?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
    }
    
    //console.log("location ==>", location)
    const isHomePage = location.pathname === '/';
    return (
        <React.Fragment>
            {isHomePage && averageRating!==0 && reviewCount!==0 &&  (
    <Helmet>
        <script type="application/ld+json">
            {`
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "@id": "https://www.dacha.ae/#Organization",
                "url": "https://www.dacha.ae/",
                "name": "Real Estate Agents in Dubai | Dubai Real Estate Brokers | Dacha Real Estate",
                "description": "Want to buy, rent, or sell your property in Dubai? Dacha is listed among the top real estate companies in Dubai. Connect with the best real estate agents in Dubai today.",
                "logo": "${logoBlue}", 
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "${averageRating}",
                    "reviewCount": "${reviewCount}"
                },
                "sameAs": [
                    "https://www.facebook.com/dacharealestate/",
                    "https://www.linkedin.com/company/dacharealestate/",
                    "https://twitter.com/Dachateam",
                    "https://www.instagram.com/dacharealestate/",
                    "https://www.tiktok.com/@dacharealestate?lang=en",
                    "https://www.youtube.com/c/DachaRealEstateDubai/"
                ],
                "email": "info@dacha.ae",
                "location": [
                    {
                        "@type": "Place",
                        "name": "Dacha - Real Estate Agents in Dubai Marina",
                        "telephone": "+971 4 423 2006",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Office 2101 & 2404, Marina Plaza, Dubai Marina",
                            "addressLocality": "Dubai",
                            "addressRegion": "UAE",
                            "postalCode": "171418",
                            "addressCountry": "UAE"
                        }
                    },
                    {
                        "@type": "Place",
                        "name": "Dacha - Real Estate Agents in Palm Jumeirah",
                        "telephone": "+971 4 423 2006",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "7 Palm Jumeirah Road, The Palm Jumeirah",
                            "addressLocality": "Dubai",
                            "addressRegion": "UAE",
                            "addressCountry": "UAE"
                        }
                    }
                ],
                "parentOrganization": {
                    "@type": "Organization",
                    "@id": "https://www.dacha.ae/#Organization",
                    "url": "https://www.dacha.ae/",
                    "name": "Real Estate Agents in Dubai | Dubai Real Estate Brokers | Dacha Real Estate",
                    "alternateName": "Dacha Real Estate"
                }
            }
            `}
        </script>
    </Helmet>
)}

            <section className="footer">
                <Container className="pad-50 featured-properties">
                    <div className="menu-wrapper justify-content-between">
                        {menus && menus.Add_Menu.map((parentMenu, index, active) => {
                            return (
                                <ul className="menu-bar">
                                    <h4 key={index}> {parentMenu.Label}</h4>
                                    {parentMenu.Sub_Menus.map((subMenu, index, active) => {
                                        var url = "#"
                                        if (subMenu.Link) {
                                            url = `/${GetURL(subMenu.Link.id)}`
                                        }
                                        return (<li key={index}><Link to={url}> {subMenu.Label}</Link></li>)
                                    })}
                                </ul>
                            )
                        })}
                    </div>
                    {
                        props.popularSearch &&
                        <div className="popular-search-list">
                            <h4 onClick={e => displaySearch()}>
                                Popular Searches <i className={showSearch ? "icon-plus" : "icon-minus"}></i>
                            </h4>
                            <div className={showSearch ? "d-none" : ""}>
                                <PopularSearch popular_search={props.popularSearch} />
                            </div>
                        </div>
                    }

                    <div className="footer-info">
                        <div>
                            <ul className="social-icons d-xl-none">
                                {site.Facebook_Link &&
                                    <li>
                                        <Link href={site.Facebook_Link} rel="noopener" target="_blank">
                                            <i className="icon-fb" alt="facebook logo"></i>
                                        </Link>
                                    </li>
                                }
                                <li>
                                    <Link href={'https://twitter.com/Dachateam'} target="_blank">
                                        <i className="icon-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'https://www.tiktok.com/@dacharealestate?lang=en'} target="_blank">
                                        <i className="icon-tiktok"></i>
                                    </Link>
                                </li>
                                {site.Instagram_Link &&
                                    <li>
                                        <Link href={site.Instagram_Link} rel="noopener" target="_blank">
                                            <i className="icon-insta" alt="instagram logo"></i>
                                        </Link>
                                    </li>
                                }
                                {site.Youtube_Link &&
                                    <li>
                                        <Link href={site.Youtube_Link} rel="noopener" target="_blank">
                                            <i className="icon-youtube" alt="youtube logo"></i>
                                        </Link>
                                    </li>
                                }
                                {site.Linkedin_Link &&
                                    <li>
                                        <Link href={site.Linkedin_Link} rel="noopener" target="_blank">
                                            <i className="icon-linkedln" alt="linkedln logo"></i>
                                        </Link>
                                    </li>
                                }
                            </ul>
                            <div class="details">
                                <div className="call-us">
                                    <i className="icon-phone">

                                    </i>
                                    <Link href={`tel:${number}`} >{number}</Link>
                                </div>
                                <div className="footer-content">
                                    {site.Available_Timings &&
                                        <div className="available-timing">
                                            {HTMLReactParser(site.Available_Timings.replace(/<br\s*\/?>/gi, ''))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="footer-logo">
                            <Link href="#">
                                <img loading="lazy" src={logoBlue} alt="Dacha logo" />
                            </Link>
                            <span className="privacy-policy"><Link to="/privacy-policy/">Privacy Policy</Link></span>
                            <span className="copy-right">
                                Copyright © {year} Dacha Real Estate
                                <a href="https://starberry.tv/" rel="noopener nofollow" target="_blank" alt="starberry-logo" className="starberry-logo">Site by <StarBerry /></a>
                            </span>
                        </div>

                    </div>

                </Container>
                { renderComponent && (
                    <div className="d-none d-lg-block">
                        <a href={whatsapp_link} target="_blank" className="whatsapp"><img loading="lazy" src={whatsapp} alt="whatsapp" /></a>
                    </div>
                )}
            </section>
        </React.Fragment >
    )
}

export default Footer